import * as React from "react"
import {graphql} from "gatsby";
import PostsList from '../components/PostsList'
import Layout from '../components/Layout'
import Container from "@mui/material/Container";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import {Link, useTranslation, useI18next} from "gatsby-plugin-react-i18next";
import Typography from "@mui/material/Typography";
import RssFeedIcon from '@mui/icons-material/RssFeed';
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";


export const query = graphql`
  query ($language: String!, $skip: Int!, $limit: Int!) {

    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allArchiveCaveSoftPost(
      filter: {lang: {eq: $language}}
      sort: {fields: [write_date], order: [DESC]}
      skip: $skip
      limit: $limit
    ) {
      nodes {
        id_odoo
        write_date
        title
        content
        slug
        image_sources
        video_sources
        short_content
        tag_ids {
          id_odoo
          title
          slug
        }
      }
    }
  }
`

/**
 * @returns {JSX.Element}
 * @constructor
 */
function Posts({data: {allArchiveCaveSoftPost: {nodes: posts}}, pageContext: {numPages, currentPage}}) {

  const {t} = useTranslation();
  const {defaultLanguage, language} = useI18next();

  // noinspection JSValidateTypes
  return (
    <Layout>
      <Container maxWidth="sm" disableGutters>
        <Paper sx={{mx: 1, my: 2, p: 1, backgroundColor: 'aliceblue', display: 'flex', justifyContent: "center", alignItems: "center"}}>
          <Typography variant="body2">
            {t("Follow the posts of this page using this ")}
            <Box component="a" href={`${language === defaultLanguage ? '' : '/' + language}/rss.xml`}>
              {t("RSS feed")}
              <RssFeedIcon sx={{verticalAlign: 'bottom'}} color="warning"/>
            </Box>
          </Typography>
        </Paper>
      </Container>
      <PostsList posts={posts}/>
      <Container sx={{my: 7}} maxWidth="xs">
        <Pagination
          size="large"
          variant="outlined"
          shape="rounded"
          page={currentPage}
          count={numPages}
          renderItem={item => <PaginationItem
            {...item}
            component={Link}
            to={item.page === 1 ? `/` : `/posts/${item.page}`}
            disabled={item.disabled || item.selected}
          />}
        />
      </Container>
    </Layout>
  )
}

export default Posts
